<template>
  <vf-input
    v-bind="$props"
    v-model="modelValue"
    :type="showPassword ? 'text' : 'password'"
    required
    data-test-id="input-password"
    @change="$emit('change', $event)"
  >
    <slot>{{ $t.password }}</slot>
    <template #end>
      <slot name="end">
        <base-button
          :aria-label="$t.showPassword"
          :aria-pressed="showPassword"
          class="flex center"
          @click="showPassword = !showPassword"
        >
          <vf-icon :name="showPassword ? 'hidden' : 'visible'" size="md" />
        </base-button>
      </slot>
    </template>
  </vf-input>
</template>

<script lang="ts" setup>
defineProps<{
  placeholder?: string
  disabled?: boolean
  invalid?: boolean
  variant?: 'inverse'
}>()

defineEmits<{ change: [value: string] }>()

const modelValue = defineModel<string>({ required: true })

const showPassword = ref(false)
</script>
